import React from "react"

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const ContactForm = ({ interest }) => {
    const intl = useIntl();
    return (
        <div className="contact-form">
            <form
                name="contact"
                method="post"
                action="/success"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
            >
                <input type="hidden" name="form-name" value="contact" />

                <div className="input-container">
                    <label htmlFor="email"><FormattedMessage id="email" /></label>
                    <input type="text" required name="email" id="email" placeholder="example@gmail.com" />
                </div>
                <div className="input-container">
                    <label htmlFor="customer_name"><FormattedMessage id="customer_name" /></label>
                    <input type="text" placeholder={intl.formatMessage({ id: "ex_name" })} name="customer_name" id="customer_name" />
                </div>
                <div className="input-container">
                    <label htmlFor="customer_phone"><FormattedMessage id="customer_phone" /></label>
                    <input type="text" placeholder={intl.formatMessage({ id: "ex_tel" })} name="customer_phone" id="customer_phone" />
                </div>
                <div className="input-container">
                    <input required type="checkbox" id="aszfcheck" name="aszfcheck" />
                    <label className="aszf-label" htmlFor="aszfcheck" >
                        <FormattedMessage id="accept_the" />
                        <Link to="/adatkezeles">
                            <FormattedMessage id="accept_the_end" />
                        </Link>
                    </label>
                </div>
                <input type="hidden" value={interest ? interest : "általános"} name="interest" id="interest" />
                <div className="contact-button">
                    <button role="button">
                        <FormattedMessage id="send" />
                    </button>
                </div>

            </form>
        </div>
    )
};

export default ContactForm;
