import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { FormattedMessage } from "gatsby-plugin-intl";

import Layout from "../components/layout";
import SEO from "../components/Seo";
import ContactForm from "../components/contactform";

import Modal from "react-modal";
import { AiOutlineClose, AiFillPlayCircle } from "react-icons/ai";

const AboutPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const image = data.bgImage.childImageSharp.fluid;
  const videoBg = data.videoImage.childImageSharp.fluid;
  const [modal, setModal] = useState(false);
  return (
    <Layout title={siteTitle}>
      <SEO title='About' keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <div id='about-page' className='container transition-fade'>
        <div className='page-about-header'>
          <h1 className='page-about-title'>
            <FormattedMessage id='about' />
          </h1>
        </div>
        <div className='about-content'>
          <div className='map-and-contact'>
            <div className='contact'>
              <h4>
                <FormattedMessage id='contact_title' />
              </h4>
              <ContactForm />
            </div>
          </div>
          <div className='about-text'>
            <span className='main'>
              <FormattedMessage id='rolunk' />
            </span>
            <h3>
              <FormattedMessage id='kreativitas_minoseg' />
            </h3>
            <FormattedMessage id='kreativitas_minoseg_tart' />
            <h3>
              <FormattedMessage id='kuldetes_ertekek' />
            </h3>
            <FormattedMessage id='kuldetes_ertekek_tart' />
            <h3>
              <FormattedMessage id='hatas_fejlodes' />
            </h3>
            <FormattedMessage id='hatas_fejlodes_tart' />

            <hr />

            <div className='text-center'>
              <p className='lead'>
                <FormattedMessage id='country_prize_won' />
                <br />
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://dehir.hu/debrecen/orszagos-epiteszeti-palyazatot-nyertek-egy-debreceni-tarsashaz-terveivel/2018/01/18/'
                >
                  <FormattedMessage id='read_more' />
                </a>
              </p>

              <br />
              <p className='lead'>
                <i>
                  “<FormattedMessage id='about_citate' />”<br />
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.octogon.hu/magazin/3-17/'
                  >
                    <FormattedMessage id='read_more' />
                  </a>
                </i>
              </p>
            </div>
          </div>
        </div>
        <BackgroundImage fluid={image} className='about-bg'>
          &nbsp;
        </BackgroundImage>

        <div className='about-video'>
          <div className='project-content-image' onClick={() => setModal(true)}>
            <Img className='kg-image' fluid={videoBg} alt='about video' />

            <div className='play-btn-holder'>
              <span className='video-start-btn'>
                <AiFillPlayCircle size='6em' />
              </span>
            </div>
          </div>

          <Modal
            isOpen={modal}
            onRequestClose={() => setModal(false)}
            className='videoModal'
            contentLabel='Video Modal'
          >
            <video className='lakasvideo' controls>
              <source
                src={`https://iproperty.hu/videos/iproperty.mp4`}
                type='video/mp4'
              />
            </video>
            <span className='close-icon' onClick={() => setModal(false)}>
              <AiOutlineClose size='3em' color='#fff' />
            </span>
          </Modal>
        </div>
      </div>
    </Layout>
  );
};

const aboutPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "plan_bg.png" }) {
      id
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    videoImage: file(relativePath: { eq: "vieo_bg.png" }) {
      id
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;


const AboutPageContainer = (props) => {
  const data = useStaticQuery(aboutPageQuery);
  return <AboutPage {...props} data={data} />;
};

export default AboutPageContainer;